<template>
    <div id="chart"> 
      <div>
<v-responsive :aspect-ratio="16/9" max-height="100%">
  <v-card
class="mx-auto"
max-width="100%"
height="900px"
>
<v-row  >
<v-col
  cols="12"
  sm="8"
  md="8" 
>
<v-card   class="align-content-center mx-auto"  height="470" > 
  <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
           Cola de envios
          </v-list-item-title> 
        </v-list-item-content>
      </v-list-item>
      <canvas id="myChart"></canvas>
     
</v-card>

</v-col>
<v-col
  cols="12"
  sm="8"
  md="6"
> 
 
</v-col>
</v-row>
<v-row  >
<v-col
  cols="12"
  sm="12"
  md="12"
> 
  <v-card     height="435"> 
  
      <canvas   height="70%" id="myChart2"></canvas>
   </v-card>
</v-col> 
</v-row>




</v-card>
</v-responsive>

</div>
    </div>
   
</template>
<script> 

import Chart from 'chart.js/auto';
import SocketIo from 'socket.io-client'
  export default {
    
    name:'Dashboard', 
    data: () => ({
      show: false,
      headers: [
        { text: 'HSM',  value: 'nombre_hsm' },
        { text: 'Responsable', value: 'capturista' },
        { text: 'Total envios', value: 'envios' }, 
      ],
      search:null, 
      loading:false,
      datosCola : [12, 19, 3, 5, 2, 3],
      labelDia :['6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
      datosDia :[],
      enviosmes :[],
      labelsmes :[],
      desserts: [],
   
    }),
    mounted(){
      let data = {
          query: {
              "Tipo": "7"
          }
      }
      
      const ctx = document.getElementById('myChart');
      var socketCola = SocketIo.connect('https://socket-ws.salud-digna.site',data) 
      socketCola.on('colaRabbit',  (resp) => { 
        console.log(resp)
        this.datosDia = [] 

        this.labelDia.forEach(element => { 

            let dat = resp.envios.filter(f=> f.hora == Number(element)) 

            
            if(dat.length>0){ 
              this.datosDia.push(Number(dat[0].sum))
            }else{
              this.datosDia.push(0)
              
            }

            dat = 0 
             
          }) 
          new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.labelDia,
                        datasets: [{
                            label: 'Envios dela hora',
                            data: this.datosDia,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'white' 
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
              });
       
       
      })
 
       

         const ctx2 = document.getElementById('myChart2');
         

     var socket = SocketIo.connect('https://socket-ws.salud-digna.site',data) 
      socket.on('envios',  (resp) => {
        console.log(resp)
        this.enviosmes =[]
        this.labelsmes = [] 
          resp.enviosmes.forEach(element => {
            this.labelsmes.push(element.fecha_envio)
            this.enviosmes.push(element.sum)
          })

          new Chart(ctx2, {
                    type: 'bar',
                    data: {
                        labels: this.labelsmes ,
                        datasets: [{
                            label: 'Envios del dia',
                            data: this.enviosmes,
                            backgroundColor: [
                            '#e53935',
                            '#d81b60',
                            '#8e24aa',
                            '#5e35b1',
                            '#3949ab',
                            '#1e88e5',
                            '#039be5',
                            '#00acc1',
                            '#00897b',
                            '#43a047',
                            '#7cb342',
                            '#c0ca33',
                            '#fdd835',
                            '#ffb300',
                            '#fb8c00',
                            '#f4511e',
                            '#6d4c41',
                            '#757575',
                            '#546e7a',
                            '#bdbdbd',
                            '#bdbdbc',
                            '#bdbdb1'
                            ],
                            borderColor: [
                                'white' 
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
              }); 
       
      })
    }
  }
</script>